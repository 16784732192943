import React from "react";
import Axios from "axios";

function salesReportPrinter(printData) {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let xmlData = tillData.tillAccess.cwrTill.salesReportTemplate.xmlcode2;

  let itemDetails = "";
  printData[0].items.map((itemsData) => {
    itemDetails += `
    <line>
          <text align ="left" length="24">${itemsData?.name.substring(0, 23).replace(/&/g, "&amp;")}</text>
          <text align ="right" length="5">${itemsData?.weight}</text>
          <text align ="right" length="13">${(itemsData?.nettotal).toFixed(2)} </text>
      </line>
      <line>
      <text align ="left" length="24">${itemsData?.value?.substring(0, 23)}</text>
      </line>
    `;
  });
  xmlData = xmlData.replaceAll("@@storeName@@", `${tillData.tillAccess.csBunit.name}`);
  xmlData = xmlData.replaceAll("@@tillID@@", `${tillData.tillAccess.cwrTill.till}`);
  xmlData = xmlData.replaceAll("@@businessDate@@", printData[0]?.date?.split(" ")[0]);
  xmlData = xmlData.replaceAll("@@user@@", `${localStorage.getItem("username")}`);
  xmlData = xmlData.replaceAll("@@time@@", `${printData[0].date}`);
  xmlData = xmlData.replaceAll("@@itemDetails@@", `${itemDetails}`);
  xmlData = xmlData.replaceAll("@@totalAmount@@", `${(printData[0].grossTotal + printData[0].grossReturnTotal).toFixed(2)}`);

  console.log("salesReportPrinter", printData);
  console.log("salesReportPrinter", xmlData);
  Axios.post(`${printerURL}printer`, xmlData, {
    headers: {
      "Content-Type": "application/xml; charset=UTF-8",
      Accept: "application/xml",
    },
  })
    .then(() => {
      console.log("XML print success !");
    })
    .catch((response) => {
      console.log("XML print failed1 !", response);
    });
}

export default salesReportPrinter;
