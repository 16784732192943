import React from "react";
import Axios from "axios";
import { Card, Row, Col, Image, Button, Spin, Modal, message } from "antd";
import first1 from "../../assets/images/first1.svg";
import logo from "../../assets/images/NewLogoCW.svg";
import red from "../../assets/images/red.svg";
import green from "../../assets/images/green.svg";
import posImg from "../../assets/images/posImg.svg";
import laptop from "../../assets/images/tillRegistation.png";
import db from "../../database";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { timeStamp } from "../../utility/timestamp";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import { useHistory } from "react-router-dom";
import { syncTillData } from "../Sync/syncData";

const Till = () => {
  const appUrl = process.env.REACT_APP_AppsUrl;
  const serverUrl = process.env.REACT_APP_serverUrl;
  const redirectUrl = process.env.REACT_APP_redirectURL;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const [runEffect, setRunEffect] = useState(true);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tills, setTills] = useState();
  const [tillValue, setTillValue] = useState(JSON.parse(localStorage.getItem("tillValue")));
  const islocked = localStorage.getItem("locked");
  let email = "";
  let Enterprise = "";
  let username;
  let cleanToken;
  const authHeaders = getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    email = authHeaders.email;
    Enterprise = authHeaders.Enterprise;
    cleanToken = authHeaders.access_token;
    username = authHeaders.username;
  } else {
    window.location.assign(`${SSOURL}sign-in/?&redirect_uri=${redirectUrl}`);
  }

  useEffect(async () => {
    const tillRegistrations = await db.tillRegistrations.toArray();
    const posConfigData = JSON.parse(localStorage.getItem("posConfigData"));
    if (runEffect) {
      setRunEffect(!runEffect);
      const nameTill = tillValue?.name;
      if (tillValue && (username || email) && cleanToken && tillRegistrations.length > 0) {
        setLoading(true);
        const userData = Enterprise ? username : email;
        await syncTillData(userData, authHeaders);
        let tillData = JSON.parse(localStorage.getItem("tillData")) || "";
        if (tillData !== null && tillData !== undefined) {
          const tillAccessData = tillData.tillAccess;
          if (!tillAccessData || !Array.isArray(tillAccessData) || tillAccessData?.length === 0) return;
          const tillAccessIndex = (tillAccessData || [])?.findIndex((accessTill) => accessTill.cwrTill.till === nameTill);
          if (tillAccessIndex >= 0 || (tillAccessData && typeof tillAccessData === "object" && !Array.isArray(tillAccessData))) {
            if (islocked) {
              tillData.tillAccess = tillAccessIndex >= 0 ? tillAccessData[tillAccessIndex] : tillAccessData;
              localStorage.setItem("username", username);
              tillData.time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
              localStorage.setItem("tillData", JSON.stringify(tillData));
              localStorage.setItem("documentSequence", tillData?.tillAccess?.cwrTill?.nextAssignedNumber);
              localStorage.removeItem("locked");
              setTillValue({});
              if (posConfigData?.posType === "PRD") {
                history.push("/production");
              } else {
                history.push("/pos");
              }
            } else if (posConfigData?.posType === "PRD") {
              setTillValue({});
              history.push(`/login`);
            } else {
              const handleStoreOps = async () => {
                try {
                  const queryPayload = {
                    query: `query {
                      getStoreOps(storeId: "${tillValue.cSBunitID}", date: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}") {
                        status
                      }
                    }`,
                  };

                  const headers = {
                    "Content-Type": "Application/json",
                    Authorization: `${cleanToken}`,
                  };

                  // Fetch StoreOps data
                  const result = await Axios.post(serverUrl, queryPayload, { headers });

                  const storeOpsData = result?.data?.data?.getStoreOps;

                  if (!storeOpsData) {
                    // If no StoreOps data, create it
                    const mutationPayload = {
                      query: `mutation {
                        upsertStoreOps(storeOps: {
                          cSBunitID: "${tillValue.cSBunitID}"
                          date: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                          status: "OP"
                          closedBy: null
                          closeTime: null
                          openedBy: "${tillData.tillAccess[0].csUserId}"
                          openTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                          remarks: null
                        }) {
                          status   
                          message
                        }
                      }`,
                    };

                    const resStoreOps = await Axios.post(serverUrl, mutationPayload, { headers });

                    const upsertData = resStoreOps?.data?.data?.upsertStoreOps;

                    if (upsertData?.status === "200") {
                      setTillValue({});
                      history.push(`/login`);
                    } else {
                      setTillValue({});
                      message.error(upsertData?.message);
                      setLoading(false);
                    }
                  } else if (storeOpsData.status === "OP") {
                    setTillValue({});
                    history.push(`/login`);
                  } else {
                    setTillValue({});
                    setLoading(false);
                    Modal.confirm({
                      title: `This till has been closed "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"`,
                      icon: null,
                      cancelText: "",
                      okText: "Okay",
                      cancelButtonProps: { style: { display: "none" } }, // Disable the cancel button
                      onOk() {
                        getTillData();
                      },
                    });
                  }
                } catch (error) {
                  setTillValue({});
                  console.error("An error occurred:", error);
                  message.error(error);
                  setLoading(false);
                }
              };
              handleStoreOps();
            }
          } else {
            setTillValue({});
            setLoading(false);
            errorMessage("Login Failed", "User not configured for this Till", "user");
          }
        } else {
          setLoading(false);
        }
      } else {
        getTillData();
      }
    }
  }, [runEffect]);

  const getTillData = async () => {
    if (authHeaders && cleanToken && (username || email)) {
      setLoading(true);

      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const userid = userData?.user_id;

        const getTillMutation = {
          query: `query {
          getTills(name: null userId: "${userid}") {
            cwrTillID
            till
            searchKey
            cSBunitID
            status
            name
            cSUserId
            userName
            tillHistory {
              cwrTillRegHistoryId
              regTimestamp
              ram
              processor
              sessionid
              macAddress
              domain
              localIpAddress
              csBunitId
            }
          }
        }`,
        };

        const headers = {
          "Content-Type": "application/json",
          Authorization: `${cleanToken}`,
        };

        const response = await Axios.post(serverUrl, getTillMutation, {
          headers,
          async: true,
          crossDomain: true,
        });

        const tillsData = response?.data?.data?.getTills ?? [];
        const transformedData = tillsData.reduce((result, item) => {
          const { name } = item;

          const existingGroupIndex = result.findIndex((group) => group[name]);
          if (existingGroupIndex === -1) {
            result.push({ [name]: [item] });
          } else {
            result[existingGroupIndex][name].push(item);
          }

          return result;
        }, []);

        setTills(transformedData);
      } catch (error) {
        if (!error.response) {
          console.error("Network error: Unable to connect to the server", error);
          message.error("Network error: Please check your internet connection and try again.");
        } else {
          console.error("Error while fetching till data:", error);
          message.error("An error occurred while fetching till data. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const callTillRegistration = async (cwrTillID, search_key) => {
    try {
      // Generate a unique sync ID and timestamp
      const syncId = uuidv4().replace(/-/g, "").toUpperCase();
      const timeMark = timeStamp();

      // Prepare the GraphQL mutation query
      const mutationQuery = {
        query: `mutation {
          tillRegistration(uniqueId: "${syncId}", tillId: "${cwrTillID}", storeOpsTillId: null,
            till: {
              searchKey: "${search_key}"
              created: "${timeMark}"
              updated: "${timeMark}"
              tillAccess: { csUser: { username: "${Enterprise ? username : email}" } }
              tillHistory: {
                domain: null
                sessionid: null
                osVersion: null
                localIpAddress: null
                macAddress: null
                processor: null
                ram: null
                regTimestamp: "${timeMark}"
              }
            }
          ) {
            status
            message
            searchKey
            tillAccess {
              salesRep
              salesRepId
              csClientId
              csUser {
                csUserId
              }
              cwrTill {
                till
                searchKey
                description
                nextAssignedNumber
              }
              csBunit {
                csBunitId
                name
                cwrSCustomerId
                csCurrency {
                  csCurrencyId
                  currSymbol
                  isoCode
                  stdPrecision
                  cstgPrecision
                  prcPrecision
                }
              }
            }
            tillHistory {
              domain
              sessionid
              osVersion
              localIpAddress
              macAddress
              processor
              ram
              regTimestamp
              cwrTillRegHistoryId
            }
          }
        }`,
      };

      // Perform the API request
      const response = await Axios.post(serverUrl, mutationQuery, {
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });

      const tillData = response?.data?.data?.tillRegistration;

      // Log the till data response
      console.log(tillData, "----------> Till Data Response");

      // Handle the response
      if (tillData?.status === "200" && tillData.tillAccess?.length > 0) {
        localStorage.setItem("syncId", syncId);
        localStorage.setItem("documentSequence", tillData.tillAccess[0].cwrTill?.nextAssignedNumber);

        // Add till data to IndexedDB
        await db.tillRegistrations.add(tillData);

        // Log POS activity
        const activityMutation = {
          query: `mutation {
            upsertPOSActivity(tillActivity: [
              {
                csBunitId: "${tillData.tillAccess[0].csBunit.csBunitId}"
                csUserId: "${tillData.tillAccess[0].csUser.csUserId}"
                tillRegistrationId: "${tillData.tillHistory[0].cwrTillRegHistoryId}"
                type: "LI"
                time: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
              }
            ]) {
              status
              message
            }
          }`,
        };

        const activityResponse = await Axios.post(serverUrl, activityMutation, {
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${cleanToken}`,
          },
        });

        if (activityResponse?.data?.data?.upsertPOSActivity?.status === "200") {
          setTillValue({});
          history.push(`/login`);
        }
      } else {
        setTillValue({});
        throw new Error(tillData?.message || "Till registration failed.");
      }
    } catch (error) {
      setTillValue({});
      console.error("An error occurred:", error);
      setLoading(false);
      errorMessage(error.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const onRegistration = async (search_key, csbUnitId, csUserId, cwrTillID) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userid = userData?.user_id;

    let posConfig = {};
    if (username || email) {
      try {
        setLoading(true);
        const userData = Enterprise ? username : email;
        await syncTillData(userData, authHeaders);
        const tillData = JSON.parse(localStorage.getItem("tillData"));
        localStorage.setItem("tillData", JSON.stringify(tillData));
        const posConfigResponse = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `
              query {
                getPOSConfig(tillId: "${cwrTillID}", name: null) {
                  cwrPosConfigId
                  name
                  posType
                  application
                  configJson
                  PricingRule
                  ExpiryDiscount
                  activateExpiryDiscount
                  registrationTypes
                  feedback
                }
              }`,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `${cleanToken}`,
          },
        });
        if (posConfigResponse?.data?.data?.getPOSConfig?.length > 0) {
          posConfig = posConfigResponse.data.data.getPOSConfig[0];
          const posConfigData = JSON.parse(posConfig.configJson || "{}");
          const feedbackData = JSON.parse(posConfig.feedback || "{}");
          const registrationTypes = JSON.parse(posConfig.registrationTypes || "[]");

          localStorage.setItem("registrationTypes", JSON.stringify(registrationTypes));
          localStorage.setItem("posConfig", JSON.stringify(posConfigData));
          localStorage.setItem("posConfigData", JSON.stringify(posConfig));
          localStorage.setItem("feedbackJson", JSON.stringify(feedbackData));
        } else {
          errorMessage("Login Failed", "Error in getting POS configuration");
        }
        if (posConfig?.posType === "PRD") {
          await callTillRegistration(cwrTillID, search_key);
        } else {
          await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query{
                      getStoreOps(storeId:"${csbUnitId}",date:"${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"){
                         status
                      }
                  }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${cleanToken}`,
            },
          }).then(async (result) => {
            if (result?.data?.data?.getStoreOps === null) {
              await Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `mutation {
                          upsertStoreOps(storeOps: {
                              cSBunitID: "${csbUnitId}"
                              date: "${moment(new Date()).format("YYYY-MM-DD")}"
                              status: "OP"
                              closedBy: null
                              closeTime: null
                              openedBy: "${userid}"
                              openTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                              remarks: null
                            }) {
                            status   
                            message
                          }
                        }
                        `,
                },
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${cleanToken}`,
                },
              }).then(async (resStoreOps) => {
                if (resStoreOps?.data?.data?.upsertStoreOps?.status === "200") {
                  let syncId = uuidv4().replace(/-/g, "").toUpperCase();
                  const timeMark = timeStamp();
                  const resData = await Axios({
                    url: serverUrl,
                    method: "POST",
                    data: {
                      query: `mutation {
                                tillRegistration(uniqueId: "${syncId}", tillId: "${cwrTillID}", storeOpsTillId: null,
                                  till: {
                                    searchKey: "${search_key}"
                                    created: "${timeMark}"
                                    updated: "${timeMark}"
                                    tillAccess: { csUser: { username: "${Enterprise ? username : email}" } }
                                    tillHistory: {
                                      domain: null
                                      sessionid: null
                                      osVersion: null
                                      localIpAddress: null
                                      macAddress: null
                                      processor: null
                                      ram: null
                                      regTimestamp: "${timeMark}"
                                    }
                                  }
                                ) {
                                  status
                                  message
                                  searchKey
                                  tillAccess {
                                    salesRep
                                    salesRepId
                                    csClientId
                                    csUser {
                                      csUserId
                                    }
                                    cwrTill {
                                      till
                                      searchKey
                                      description
                                      nextAssignedNumber
                                    }
                                    csBunit {
                                      csBunitId
                                      name
                                      cwrSCustomerId
                                      csCurrency {
                                        csCurrencyId
                                        currSymbol
                                        isoCode
                                        stdPrecision
                                        cstgPrecision
                                        prcPrecision
                                      }
                                    }
                                  }
                                  tillHistory {
                                    domain
                                    sessionid
                                    osVersion
                                    localIpAddress
                                    macAddress
                                    processor
                                    ram
                                    regTimestamp
                                    cwrTillRegHistoryId
                                  }
                                }
                              }`,
                    },
                    headers: {
                      "Content-Type": "Application/json",
                      Authorization: `${cleanToken}`,
                    },
                  });
                  const tillData = resData.data.data.tillRegistration;
                  console.log(tillData, "---------->tillDataResponse");
                  localStorage.setItem("syncId", syncId);
                  if (tillData?.status === "200") {
                    await db.tillRegistrations.add(tillData);
                    localStorage.setItem("documentSequence", tillData.tillAccess[0].cwrTill.nextAssignedNumber);
                    setLoading(false);
                    await Axios({
                      url: serverUrl,
                      method: "POST",
                      data: {
                        query: `mutation {
                                    upsertPOSActivity(tillActivity: [
                                      {
                                        csBunitId: "${tillData.tillAccess[0].csBunit.csBunitId}"
                                        csUserId: "${tillData.tillAccess[0].csUser.csUserId}"
                                        tillRegistrationId: "${tillData.tillHistory[0].cwrTillRegHistoryId}"
                                        type: "LI"
                                        time: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                                      }
                                    ]) {
                                      status
                                      message
                                    }
                                }`,
                      },
                      headers: {
                        "Content-Type": "Application/json",
                        Authorization: `${cleanToken}`,
                      },
                    }).then(async (response) => {
                      if (response?.data?.data?.upsertPOSActivity?.status === 200 || response?.data?.data?.upsertPOSActivity?.status === "200") {
                        history.push(`/login`);
                      }
                    });
                  } else {
                    setLoading(false);
                    errorMessage(tillData.message);
                    console.error(tillData);
                  }
                } else {
                  console.log(resStoreOps?.data?.data?.upsertStoreOps?.message);
                  message.error(resStoreOps?.data?.data?.upsertStoreOps?.message);
                  setTillValue({});
                  setTimeout(() => {
                    for (let key in localStorage) {
                      if (key !== "userData") {
                        localStorage.removeItem(key);
                      }
                    }
                    window.location.assign("/");
                  }, 3000);
                }
              });
            } else if (result?.data?.data?.getStoreOps?.status === "OP") {
              await callTillRegistration(cwrTillID, search_key);
            } else {
              setLoading(false);
              Modal.confirm({
                title: `This till has been closed "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"`,
                icon: null,
                cancelText: "",
                okText: "Okay",
                cancelButtonProps: { style: { display: "none" } }, // Disable the cancel button
                onOk() {
                  // Close the modal
                  Modal.destroyAll();
                },
              });
            }
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        errorMessage("Error", "An error occurred while fetching till data");
      }
    }
  };

  const onModalOkay = () => {
    setLoading(false);
    const keepKey = "userData"; // Replace with the key you want to keep
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      // Check if the current key is not the one you want to keep
      if (key !== keepKey) {
        localStorage.removeItem(key);
      }
    }
    // history.push("/")
  };

  const onUserModal = () => {
    const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());
    const domainURL = process.env.REACT_APP_domain;
    for (let i = 0; i < cookiesToDelete.length; i++) {
      const cookie = cookiesToDelete[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const domain = "domain=" + domainURL;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
    }

    // }
    localStorage.removeItem("tillSession");
    window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`);
  };

  const errorMessage = (modalTitle, modalContent, user) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
      onOk: user ? onUserModal : onModalOkay,
    });
  };

  const onTill = () => {
    if (cleanToken !== "bearer undefined") {
      setRunEffect(true);
    } else {
      window.location.assign(`${SSOURL}sign-in/?&redirect_uri=${redirectUrl}`);
    }
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
      {authHeaders ? (
        <Row>
          <Col style={{ backgroundColor: "#F5FAFF" }} span={8}>
            <div style={{ height: "100vh" }}>
              <Image preview={false} src={logo} style={{ paddingTop: "4.5vh", paddingLeft: "2.6em", width: "93%" }} />
              <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "1.8vw", paddingTop: "7vh", paddingBottom: "7vh", paddingLeft: "3em" }}>Welcome to Point of Sale</p>
              <Image preview={false} src={laptop} style={{ width: "26vw", marginLeft: "2em", height: "41vh" }} />
              <p style={{ fontFamily: "Inter", fontWeight: 600, color: "#667085", fontSize: "13px", paddingTop: "10vh", paddingLeft: "3em" }}>Point of Sale - Lite</p>
              <p style={{ fontFamily: "Inter", fontWeight: 600, color: "#667085", fontSize: "0.9vw", paddingTop: "0.5em", paddingLeft: "3em" }}>
                {" "}
                Centralize the operations of your store, process transactions, manage inventory, follow orders, create reports of the store’s activities and much more!
              </p>
            </div>
          </Col>
          <Col span={16}>
            <div style={{ display: "flex" }}>
              <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "20px", marginTop: "1.4em", paddingLeft: "1.4em" }}>Select a Till to login</p>
              <div style={{ marginLeft: "auto", marginRight: "2em" }}>
                <Button
                  onClick={() => window.location.assign(`${appUrl}`)}
                  style={{ backgroundColor: "#0C173A", marginTop: "2.5em", fontSize: "10px", borderRadius: "4px", color: "white", fontFamily: "Inter", fontWeight: 600 }}
                >
                  Back to My Apps
                </Button>
              </div>
            </div>

            {authHeaders?.access_token &&
              (authHeaders?.username || authHeaders?.email) &&
              tills?.map((e, data) => (
                <Row style={{ marginLeft: "2em" }}>
                  <Col span={24}>
                    <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "1vw", paddingTop: "0.5em", paddingLeft: "2.2em", color: "#585768", marginBottom: "1vh" }}>
                      {Object.keys(e)[0]}
                    </p>
                  </Col>
                  {e[Object.keys(e)[0]].map((res) => (
                    <Col span={5} key={res.cwrTillID}>
                      {" "}
                      <Card
                        id="step1"
                        bodyStyle={{ padding: "1vw" }}
                        style={{
                          height: "7.5em",
                          width: "11em",
                          borderRadius: "6px",
                          border: " 1px solid #EEEEEF",
                          boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.04)",
                          backgroundColor: "#F3F4F9",
                          cursor: "pointer",
                        }}
                        onClick={(event) => {
                          localStorage.setItem("tillValue", JSON.stringify({ search_key: res.searchKey, cwr_till_id: res.cwrTillID, name: res.till, cSBunitID: res.cSBunitID }));
                          onRegistration(res.searchKey, res.cSBunitID, res.cSUserId, res.cwrTillID);
                        }}
                      >
                        <div>
                          <Image preview={false} src={posImg} style={{ height: "1.5vw" }} />
                          <span style={{ marginLeft: "1em", top: "15px" }}>{res.till}</span>
                        </div>
                        <div style={{ paddingTop: "1em" }}>
                          <Image preview={false} src={res.status === "AVL" ? green : res.status === "INU" ? red : green} style={{ height: "0.9vw" }} />
                          <span style={{ marginLeft: "5px", fontSize: "1vw", fontWeight: 600 }}>
                            {res.status === "AVL" ? "Available" : res.status === "INU" ? "In Use" : "Not Registered"}
                          </span>
                        </div>
                        <p style={{ fontFamily: "Inter", fontWeight: 600, marginLeft: "1.3em", color: "#667085", fontSize: "0.9vw" }}>{res.userName}</p>
                      </Card>
                    </Col>
                  ))}
                </Row>
              ))}
            {tillValue?.search_key && (
              <Row style={{ marginLeft: "2em" }}>
                <Col span={5} key={tillValue.cwrTillID}>
                  <Card
                    id="step1"
                    bodyStyle={{ padding: "1vw" }}
                    style={{
                      height: "7.5em",
                      width: "11em",
                      borderRadius: "6px",
                      border: " 1px solid #EEEEEF",
                      boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.04)",
                      backgroundColor: "#F3F4F9",
                      cursor: "pointer",
                    }}
                    onClick={onTill}
                  >
                    <div>
                      <Image preview={false} src={posImg} style={{ height: "1.5vw" }} />
                      <span style={{ marginLeft: "1em", top: "15px" }}>{tillValue?.name}</span>
                    </div>
                    <div style={{ paddingTop: "1em" }}>
                      <span style={{ marginLeft: "5px", fontSize: "1vw", fontWeight: 600 }}></span>
                    </div>
                    <p style={{ fontFamily: "Inter", fontWeight: 600, marginLeft: "1.3em", color: "#667085", fontSize: "0.9vw" }}>{tillValue?.userName}</p>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      ) : (
        <Row></Row>
      )}
    </Spin>
  );
};
export default Till;
