import moment from "moment";
import { message } from "antd";

export const CheckoutTotalManualDiscount = async (
  pricingRule,
  setCart,
  cart,
  orderType,
  cartObj,
  discountInput,
  matchingApproval,
  iscoupon,
  couponInput,
  uniqReferenceId,
  mPricingCouponId,
  mPricingRulesId
) => {
  const saleTypeValidation = pricingRule.cwrSaletypeId === null ? true : pricingRule.cwrSaletypeId === orderType.cwrSaletype.cwrSaletypeId;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const taxIncludeFlag = tillData.tillAccess.csBunit.isTaxIncluded ? tillData.tillAccess.csBunit.isTaxIncluded : "Y";
  const billCart = { ...cartObj };
  let nonReturnItemsTotal = 0;
  let nonReturnItemsQty = 0;
  let nonReturnItemsDiscount = 0;
  let nonReturnItems = [];
  billCart.items.map((item) => {
    if (!item.isReturn) {
      nonReturnItemsTotal += item.nettotal;
      nonReturnItemsQty += item.weight;
      nonReturnItemsDiscount += item.discount;
      nonReturnItems.push(item);
    }
  });

  let maxAmount = pricingRule.maxBillAmount ? Math.abs(nonReturnItemsTotal) <= pricingRule.maxBillAmount : true;
  const minQtyFlag = pricingRule.minimumQty ? Math.abs(nonReturnItemsQty) >= pricingRule.minimumQty : true;
  const maxQtyFlag = pricingRule.maximumQty ? Math.abs(nonReturnItemsQty) <= pricingRule.maximumQty : true;
  const minAmountFlag = pricingRule.billAmount ? Math.abs(nonReturnItemsTotal) >= pricingRule.billAmount : true;
  const maxAmountFlag = pricingRule.maxBillAmount ? Math.abs(nonReturnItemsTotal) <= pricingRule.maxBillAmount : true;
  let giftCardFlag = false;
  let uniqueFinalArray = [];

  nonReturnItems.forEach((addedToCart) => {
    let excludingFlag = true;

    if (addedToCart?.layAway === "Y" && addedToCart?.weight <= 0) {
      excludingFlag = false;
    }

    const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === addedToCart.mProductCategoryId);

    if (
      (pricingRule.excludeProductCategories === "Y" && matchingCategoy) ||
      (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy) ||
      (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingProduct = pricingRule.mPricingXProducts.find((pc) => pc.mProductId === addedToCart.productId);

    if (
      (pricingRule.excludeProducts === "Y" && matchingProduct) ||
      (pricingRule.excludeProducts !== "Y" && !matchingProduct) ||
      (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === addedToCart.productBrandId);

    if (
      (pricingRule.excludeBrands === "Y" && matchingBrand) ||
      (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
      (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
    ) {
      excludingFlag = false;
    }
    if (excludingFlag) uniqueFinalArray.push(addedToCart);
  });

  let totalBillPrice = 0;
  let validBillAmount = 0;
  uniqueFinalArray.map((product) => {
    if (product?.priority ? (product?.priority < pricingRule.priority || pricingRule.mPricingrulesId === product.mPricingruleId) && product?.nextRule !== "Y" : true) {
      totalBillPrice = totalBillPrice + parseFloat(product.realPrice * Math.abs(product.weight) - product.discount);
    }
    validBillAmount = validBillAmount + parseFloat(product.realPrice * Math.abs(product.weight));
  });

  if (totalBillPrice - discountInput < 0 && pricingRule.discountType === "V" && billCart.items.length > 0) {
    message.error(`Discount Amount cannot exceed the Total Bill Amount. Please enter a valid amount`);
    return cartObj;
  }
  let discountedAmount = ((totalBillPrice + cart.discount) * discountInput) / 100;
  if (cart.total + cart.discount - discountedAmount < 0 && pricingRule.discountType === "P" && billCart.items.length > 0) {
    message.error(`Discount Amount cannot exceed the Total Bill Amount. Please enter a valid amount`);
    return cartObj;
  }

  if (validBillAmount <= discountInput && billCart.items.length > 0) {
    message.error(`Discount amount cannot exceed the total bill amount.`);
    return cartObj;
  }

  if (
    Math.abs(nonReturnItemsTotal) + Math.abs(nonReturnItemsDiscount) >= pricingRule.billAmount &&
    maxAmount &&
    minQtyFlag &&
    maxQtyFlag &&
    minAmountFlag &&
    maxAmountFlag &&
    validBillAmount >= pricingRule.billAmount &&
    !giftCardFlag &&
    discountInput <= validBillAmount
  ) {
    let discountValue = 0;
    discountValue = pricingRule.discountType === "V" ? discountInput : (discountInput / 100) * nonReturnItemsTotal;
    let discountType = pricingRule.discountType === "V" ? "FD" : "PD";
    if (pricingRule.issueGiftVoucher === "N" || pricingRule.issueGiftVoucher === null) {
      const cartItemsFiltered = billCart.items.filter((i) => i.nextRule === "Y" || i.nextRule === undefined);
      let salePricesTotal = 0;
      for (let i = 0; i < cartItemsFiltered.length; i += 1) {
        salePricesTotal += parseFloat(cartItemsFiltered[i].realPrice * cartItemsFiltered[i].weight);
      }
      let discountFlag = false;
      billCart.items.map(async (addedToCart, index) => {
        let excludingFlag = true;

        if (addedToCart?.layAway === "Y" && addedToCart?.weight <= 0) {
          excludingFlag = false;
        }
        if (pricingRule.mPricingB2CCustomerSegments.length > 0) {
          const matchingCustomerSegment = pricingRule.mPricingB2CCustomerSegments.find(
            (pc) => pc.cwrB2CCustomerSegmentId === cartObj.customer?.b2cCustomerSegment?.cwrB2CCustomerSegmentId
          );

          if (
            (pricingRule.excludeB2CSegment === "Y" && matchingCustomerSegment) ||
            (pricingRule.excludeB2CSegment !== "Y" && !matchingCustomerSegment) ||
            (pricingRule.excludeB2CSegment === "N" && pricingRule.mPricingB2CCustomerSegments.length === 0)
          ) {
            excludingFlag = false;
          }
        }

        if (pricingRule.mPricingB2CCustomers.length > 0) {
          const matchingCustomer = pricingRule.mPricingB2CCustomers.find((pc) => pc.b2cCustomerId === cartObj.customer.cwrCustomerId);

          if (
            (pricingRule.excludeB2CCustomers === "Y" && matchingCustomer) ||
            (pricingRule.excludeB2CCustomers !== "Y" && !matchingCustomer) ||
            (pricingRule.excludeB2CCustomers === "N" && pricingRule.mPricingB2CCustomers.length === 0)
          ) {
            excludingFlag = false;
          }
        }

        const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === addedToCart.mProductCategoryId);

        if (
          (pricingRule.excludeProductCategories === "Y" && matchingCategoy) ||
          (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy) ||
          (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
        ) {
          excludingFlag = false;
        }

        const matchingProduct = pricingRule.mPricingXProducts.find((pc) => pc.mProductId === addedToCart.productId);

        if (
          (pricingRule.excludeProducts === "Y" && matchingProduct) ||
          (pricingRule.excludeProducts !== "Y" && !matchingProduct) ||
          (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
        ) {
          excludingFlag = false;
        }

        const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === addedToCart.productBrandId);

        if (
          (pricingRule.excludeBrands === "Y" && matchingBrand) ||
          (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
          (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
        ) {
          excludingFlag = false;
        }

        const matchingBusinessUnit = pricingRule.mPricingBUnits.find((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
        if (
          (pricingRule.excludeBusinessUnits === "Y" && matchingBusinessUnit) ||
          (pricingRule.excludeBusinessUnits !== "Y" && !matchingBusinessUnit) ||
          (pricingRule.excludeBusinessUnits === "N" && pricingRule.mPricingBUnits.length === 0)
        ) {
          excludingFlag = false;
        }

        const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === addedToCart.productId);

        if (
          (pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||
          (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||
          (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
        ) {
          excludingFlag = false;
        }

        let pricingRuleFlag = true;

        if (addedToCart.discount <= 0) {
          const matchingProduct = pricingRule.mPricingXProducts.find((res) => res.mProductId === addedToCart.productId);
          if (matchingProduct) {
            // console.log("Deleting priority for addedToCart:", addedToCart.productId);
            delete addedToCart.priority;
          }
        }

        if (
          (addedToCart.nextRule === "N" || !addedToCart.nextRule) &&
          pricingRuleFlag &&
          excludingFlag &&
          saleTypeValidation &&
          !addedToCart.isReturn &&
          (iscoupon ? (addedToCart?.priority ? addedToCart?.priority < pricingRule.priority : true) : true)
        ) {
          let allDiscounts = addedToCart?.allDiscounts || [];
          let approvalData = addedToCart?.approval || [];
          let matchedRuleIndex = allDiscounts.findIndex((d) => d.mPricingruleId === pricingRule.mPricingrulesId);
          let matchedApprovalIndex = approvalData.findIndex((d) => d.mPricingrulesId === pricingRule.mPricingrulesId);

          let discountAmt;
          let sp;
          if (discountType === "FD") {
            discountAmt = discountInput * ((parseFloat(addedToCart.realPrice * addedToCart.weight) - addedToCart.discount) / totalBillPrice);
            addedToCart.discount = matchedRuleIndex !== -1 && allDiscounts.length === 1 ? discountAmt : discountAmt + addedToCart.discount;
            sp = parseFloat(addedToCart.realPrice - addedToCart.discount / addedToCart.weight);
          } else {
            discountAmt = (discountInput / 100) * parseFloat(addedToCart.realPrice);
            addedToCart.discount = discountAmt * addedToCart.weight;
            sp = parseFloat(addedToCart.realPrice) - discountAmt;
          }
          if (matchedRuleIndex === -1) {
            allDiscounts.push({
              mPricingruleId: pricingRule.mPricingrulesId,
              discountValue: discountValue,
              discountType: discountType,
            });
          }
          if (matchedApprovalIndex === -1) {
            approvalData.push({
              ...matchingApproval,
              time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              mPricingrulesId: pricingRule.mPricingrulesId,
            });
          }
          discountFlag = true;
          const mrp = parseFloat(sp) * addedToCart.weight;
          const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + addedToCart.taxRate / 100) : (mrp * addedToCart.taxRate) / 100;
          addedToCart.salePrice = sp;
          addedToCart.taxAmount = tax;
          addedToCart.nettotal = taxIncludeFlag === "Y" ? mrp : mrp + tax;
          addedToCart.discountName = pricingRule.printedName;
          addedToCart.mPricingruleId = pricingRule.mPricingrulesId;
          addedToCart.iscoupon = iscoupon;
          addedToCart.couponInput = couponInput;
          addedToCart.referenceId = uniqReferenceId;
          addedToCart.mPricingCouponId = mPricingCouponId;
          addedToCart.priority = pricingRule.priority;
          addedToCart.allDiscounts = allDiscounts;
          addedToCart.nextRule = pricingRule.nextRule;
          addedToCart.approval = approvalData;
          billCart.items[index] = addedToCart;
        }
      });

      billCart.mPricingruleId = pricingRule.mPricingrulesId;
      billCart.billDiscount = billCart.billDiscount ? billCart.billDiscount + discountValue : discountValue;

      const addedToCart = billCart.items;

      let totalTax = 0;
      let totalPrice = 0;
      let totalItemsQty = 0;
      let totalDiscounts = 0;
      for (let i = 0; i < addedToCart.length; i += 1) {
        totalPrice += addedToCart[i].nettotal;
        totalItemsQty += addedToCart[i].weight;
        totalTax += addedToCart[i].taxAmount;
        totalDiscounts += addedToCart[i].discount;
        addedToCart[i].key = i;
      }

      const roundOffValue = Math.round(totalPrice);
      const totalRoundOff = totalPrice - roundOffValue;
      let couponsData = cart?.couponInput?.length > 0 ? [...cart?.couponInput] : [];

      if (!couponsData.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null && discountFlag) {
        couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: pricingRule.mPricingrulesId });
      }

      let uniqueArray = cart?.manualDiscountData?.totalLevelDiscount || [];
      let lineLevelDiscounts = cart?.manualDiscountData?.lineLevelDiscount || [];
      let totalLevelDiscounts = cart?.manualDiscountData?.totalLevelDiscount || [];

      // Get the highest sequenceNo across both arrays
      let allSequenceNos = [...lineLevelDiscounts.map((item) => item.sequenceNo), ...totalLevelDiscounts.map((item) => item.sequenceNo)];

      // Find the next available sequenceNo
      let nextSequenceNo = 1;
      while (allSequenceNos.includes(nextSequenceNo)) {
        nextSequenceNo++;
      }

      let duplicateIndex = uniqueArray.findIndex((item) => item.pricingRule === pricingRule.mPricingrulesId);
      const itemData = {
        pricingRule: pricingRule.mPricingrulesId,
        discountName: pricingRule.printedName,
        discountValue: discountInput,
        sequenceNo: duplicateIndex === -1 ? nextSequenceNo : totalLevelDiscounts[duplicateIndex]?.sequenceNo,
      };
      if (duplicateIndex === -1) {
        uniqueArray.push(itemData);
      } else {
        uniqueArray[duplicateIndex] = itemData;
      }

      let manualDiscountData = {
        totalLevelDiscount: uniqueArray,
        ...cart.manualDiscountData,
      };

      let obj = {
        ...cartObj,
        items: [...addedToCart],
        total: totalPrice,
        tax: totalTax,
        discount: totalDiscounts,
        totalQty: totalItemsQty,
        roundOff: totalRoundOff,
        iscoupon: iscoupon ? iscoupon : false,
        couponInput: couponsData,
        totalBillDicount: iscoupon ? pricingRule.mPricingrulesId : false,
        totalDiscountFlag: true,
        manualDiscountData: manualDiscountData,
      };
      localStorage.setItem("cartObj", JSON.stringify(obj));
      if (!iscoupon) {
        setCart(obj);
      }
      return obj;
    } else {
      let couponsData = cart.couponInput ? [...cart.couponInput] : [];
      if (!couponsData.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null) {
        couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: pricingRule.mPricingrulesId });
      }
      billCart.giftVoucherType = pricingRule.giftVoucherType;
      billCart.giftVoucherAmount = discountValue;
      billCart.iscoupon = cart.iscoupon ? cart.iscoupon : false;
      billCart.couponInput = couponsData;
      billCart.totalBillDicount = !iscoupon ? null : pricingRule.mPricingrulesId;
      setCart({ ...billCart });
      localStorage.setItem("cartObj", JSON.stringify({ ...billCart }));
      return { ...billCart };
    }
  } else {
    const cartItemsFiltered = billCart.items.filter((i) => i.nextRule === "Y" || i.nextRule === undefined);
    let salePricesTotal = 0;
    for (let i = 0; i < cartItemsFiltered.length; i += 1) {
      salePricesTotal += parseFloat(cartItemsFiltered[i].realPrice);
    }

    billCart.items.map((addedToCart, index) => {
      if (addedToCart.discountName === pricingRule.printedName && !addedToCart.isReturn) {
        const sp = parseFloat(addedToCart.realPrice);
        const mrp = parseFloat(sp) * addedToCart.weight;
        const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + addedToCart.taxRate / 100) : (mrp * addedToCart.taxRate) / 100;
        addedToCart.salePrice = sp;
        addedToCart.taxAmount = tax;
        addedToCart.nettotal = taxIncludeFlag === "Y" ? mrp : mrp + tax;
        addedToCart.discount = 0;
        addedToCart.discountName = "";
        addedToCart.allDiscounts = [];
        billCart.items[index] = addedToCart;
      }
    });
    billCart.mPricingruleId = pricingRule.mPricingrulesId;
    billCart.billDiscount = 0;

    const addedToCart = billCart.items;

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    let couponsData = cart?.couponInput?.length > 0 ? [...cart.couponInput] : [];
    if (!couponsData?.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null) {
      couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: pricingRule.mPricingrulesId });
    }
    let uniqueArray = cart?.manualDiscountData?.totalLevelDiscount || [];

    let lineLevelDiscounts = cart?.manualDiscountData?.lineLevelDiscount || [];
    let totalLevelDiscounts = cart?.manualDiscountData?.totalLevelDiscount || [];

    // Get the highest sequenceNo across both arrays
    let allSequenceNos = [...lineLevelDiscounts.map((item) => item.sequenceNo), ...totalLevelDiscounts.map((item) => item.sequenceNo)];

    // Find the next available sequenceNo
    let nextSequenceNo = 1;
    while (allSequenceNos.includes(nextSequenceNo)) {
      nextSequenceNo++;
    }

    let duplicateIndex = uniqueArray.findIndex((item) => item.pricingRule === pricingRule.mPricingrulesId);
    const itemData = {
      pricingRule: pricingRule.mPricingrulesId,
      discountName: pricingRule.printedName,
      discountValue: discountInput,
      sequenceNo: duplicateIndex === -1 ? nextSequenceNo : totalLevelDiscounts[duplicateIndex].sequenceNo,
    };
    if (duplicateIndex === -1) {
      uniqueArray.push(itemData);
    } else {
      uniqueArray[duplicateIndex] = itemData;
    }

    let manualDiscountData = {
      totalLevelDiscount: uniqueArray,
      ...cart.manualDiscountData,
    };
    let obj = {
      ...cartObj,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
      iscoupon: iscoupon ? iscoupon : false,
      couponInput: couponsData,
      totalBillDicount: !iscoupon ? null : pricingRule.mPricingrulesId,
      discountType: "TD",
      totalDiscountFlag: true,
      manualDiscountData: manualDiscountData,
    };
    localStorage.setItem("cartObj", JSON.stringify(obj));
    if (!iscoupon) {
      setCart(obj);
    }
    return {
      ...cartObj,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
      iscoupon: iscoupon ? iscoupon : false,
      couponInput: couponsData,
      discountType: "TD",
      totalBillDicount: !iscoupon ? null : pricingRule.mPricingrulesId,
      totalDiscountFlag: true,
      manualDiscountData: manualDiscountData,
    };
  }
};
