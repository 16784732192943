// import Dexie from "dexie";

// const db = new Dexie("cwpos");
// db.version(24340)
//   .stores({
//     tillRegistrations: "++id",
//     productCategories: "mProductCategoryId",
//     productBrands: "brandId",
//     newBrands: "brandId",
//     dataSyncSummary: "++id, syncId, syncType",
//     products: "++id, mProductId, mProductCategoryId, value, name, upc, brandId, *batchIndex, *upcIndex",
//     orders: "sOrderID, orderTime, documentno, isSynced, tillSessionId, customerSearchKey",
//     pricingRules: "++id, billAmount, mPricingrulesId",
//     posSaletypes: "++id, cwrSaletypeId",
//     productUom: "++id, csUomId",
//     tillEvents: "++id, tillSessionId, isSynced, tillStatus",
//     logConfiguration: "++id",
//     logInformation: "++id",
//     restaurantTables: "++id",
//     cashInCashOut: "++id",
//     rfidData: "tag_value",
//     restaurantTables: "++id",
//     tableData: "cwrFbTableId, name, tableSync",
//     fbOrderData: "++id,fbOrderId,cwrFbTableId,fbOrderStatus,fbOrderSync",
//     sectionTables: "++id",
//     loyalityData: "loyaltylevelId",
//     AllProductCategories: "mProductCategoryId",
//     POSWorkFlowRules: "cwrRulesId",
//     approvers: "++id",
//     giftCardData: "cwrGiftcardTypeId",
//     paymentsData: "++id, tillSessionId, orderTime, tillStatus",
//     ordersData: "++id, tillSessionId, orderTime, tillStatus",
//     logActivity: "++id",
//     salesRep: "s_salesrep_id, name,code",
//     docTypesData: "++cs_doctype_id",
//   })
//   .upgrade((tx) => {
//     // Database Migrations
//     console.log("DB Migration -> ", tx);
//   });

// // Catch any errors related to missing object stores
// db.on("blocked", () => {
//   console.error("Upgrade needed, but another connection is blocking it.");
// });

// // Open the database
// db.open().catch((err) => {
//   console.error(`Failed to open db: ${err.stack}`);
// });

// export default db;

import Dexie from "dexie";

const db = new Dexie("cwpos");
window.db = db;
db.version(24340)
  .stores({
    tillRegistrations: "++id",
    productCategories: "mProductCategoryId",
    productBrands: "brandId",
    newBrands: "brandId",
    dataSyncSummary: "++id, syncId, syncType",
    products: "++id, mProductId, mProductCategoryId, value, name, upc, brandId, *batchIndex, *upcIndex",
    orders: "sOrderID, orderTime, documentno, isSynced, tillSessionId, customerSearchKey",
    pricingRules: "++id, billAmount, mPricingrulesId",
    posSaletypes: "++id, cwrSaletypeId",
    productUom: "++id, csUomId",
    tillEvents: "++id, tillSessionId, isSynced, tillStatus",
    logConfiguration: "++id",
    logInformation: "++id",
    restaurantTables: "++id",
    cashInCashOut: "++id",
    rfidData: "tag_value",
    restaurantTables: "++id",
    tableData: "cwrFbTableId, name, tableSync",
    fbOrderData: "++id,fbOrderId,cwrFbTableId,fbOrderStatus,fbOrderSync",
    sectionTables: "++id",
    loyalityData: "loyaltylevelId",
    AllProductCategories: "mProductCategoryId",
    POSWorkFlowRules: "cwrRulesId",
    approvers: "++id",
    giftCardData: "cwrGiftcardTypeId",
    paymentsData: "++id, tillSessionId, orderTime, tillStatus",
    ordersData: "++id, tillSessionId, orderTime, tillStatus",
    logActivity: "++id",
    salesRep: "s_salesrep_id, name,code",
    docTypesData: "++cs_doctype_id",
  })
  .upgrade((tx) => {
    // Database Migrations
    console.log("DB Migration -> ", tx);
  });

// Catch any errors related to missing object stores
db.on("blocked", () => {
  console.error("Upgrade needed, but another connection is blocking it.");
});

// Keep-alive mechanism
const KEEP_ALIVE_INTERVAL = 4 * 60 * 1000; // 4 minutes
let keepAliveTimer;

function startKeepAlive() {
  if (keepAliveTimer) clearInterval(keepAliveTimer);
  keepAliveTimer = setInterval(async () => {
    try {
      if (!db.isOpen()) {
        await db.open();
        console.log("Database reopened during keep-alive check");
      }
      // Perform a minimal operation to keep the connection alive
      await db.table("logConfiguration").count();
    } catch (error) {
      console.error("Error during keep-alive operation:", error);
      await reconnectDatabase();
    }
  }, KEEP_ALIVE_INTERVAL);
}

async function reconnectDatabase() {
  console.log("Attempting to reconnect to the database...");
  try {
    if (db.isOpen()) await db.close();
    await db.open();
    console.log("Database reconnected successfully");
    startKeepAlive();
  } catch (error) {
    console.error("Failed to reconnect to the database:", error);
    // Retry reconnection after a delay
    setTimeout(reconnectDatabase, 5000);
  }
}

// Open the database initially
db.open()
  .then(() => {
    console.log("Database opened successfully");
    startKeepAlive();
  })
  .catch((err) => {
    console.error(`Failed to open db: ${err.stack}`);
    reconnectDatabase();
  });

// Handle unexpected closures
db.on("close", () => {
  console.warn("Database unexpectedly closed. Attempting to reopen...");
  reconnectDatabase();
});

// Wrap database operations with error handling
db.transaction = async function (mode, tables, callback) {
  try {
    return await Dexie.prototype.transaction.call(this, mode, tables, callback);
  } catch (error) {
    if (error.name === "InvalidStateError" && error.message.includes("connection is closing")) {
      console.warn("Caught a closing connection error. Reopening and retrying...");
      await reconnectDatabase();
      return await Dexie.prototype.transaction.call(this, mode, tables, callback);
    }
    throw error;
  }
};

db.close = function () {
  console.log("Database manually closed for testing.");
  Dexie.prototype.close.call(this); // Call original close method
  reconnectDatabase(); // Ensure reconnection happens after manual close
};

export default db;
