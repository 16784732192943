import moment from "moment";

export const CheckoutFlatDiscount = async (
  product,
  pricingRule,
  setCart,
  cart,
  orderType,
  cartObj,
  discountValue,
  matchingApproval,
  iscoupon,
  couponInput,
  uniqReferenceId,
  mPricingCouponId,
  mPricingRulesId,
  removeDiscounts
) => {
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let hasChanges = false;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const taxIncludeFlag = tillData.tillAccess.csBunit.isTaxIncluded ? tillData.tillAccess.csBunit.isTaxIncluded : "Y";
  // Iterate through each item in the cart
  cartObj.items.map((item) => {
    let excludingFlag = true;

    const matchingCustomerSegment = pricingRule.mPricingB2CCustomerSegments.find(
      (pc) => pc.cwrB2CCustomerSegmentId === cartObj.customer?.b2cCustomerSegment?.cwrB2CCustomerSegmentId
    );

    if (
      (pricingRule.excludeB2CSegment === "Y" && matchingCustomerSegment) ||
      (pricingRule.excludeB2CSegment !== "Y" && !matchingCustomerSegment && pricingRule.excludeB2CSegment) ||
      (pricingRule.excludeB2CSegment === "N" && pricingRule.mPricingB2CCustomerSegments.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingCustomer = pricingRule.mPricingB2CCustomers.find((pc) => pc.b2cCustomerId === cartObj.customer.cwrCustomerId);
    if (
      (pricingRule.excludeB2CCustomers === "Y" && matchingCustomer) ||
      (pricingRule.excludeB2CCustomers !== "Y" && !matchingCustomer) ||
      (pricingRule.excludeB2CCustomers === "N" && pricingRule.mPricingB2CCustomers.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingBusinessUnit = pricingRule.mPricingBUnits.find((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
    if (
      (pricingRule.excludeBusinessUnits === "Y" && matchingBusinessUnit) ||
      (pricingRule.excludeBusinessUnits !== "Y" && !matchingBusinessUnit) ||
      (pricingRule.excludeBusinessUnits === "N" && pricingRule.mPricingBUnits.length === 0)
    ) {
      excludingFlag = false;
    }

    // Check if there are any changes
    const matchingCategory = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === item.mProductCategoryId);
    if (
      (pricingRule.excludeProductCategories === "Y" && matchingCategory) ||
      (pricingRule.excludeProductCategories !== "Y" && !matchingCategory) ||
      (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === item.productBrandId);
    if (
      (pricingRule.excludeBrands === "Y" && matchingBrand) ||
      (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
      (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === item.productId);
    if (
      (pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||
      (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||
      (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
    ) {
      excludingFlag = false;
    }

    let pricingRuleFlag = true;

    if (item.discount <= 0) {
      delete item.priority;
    }

    if (
      saleTypeValidation &&
      excludingFlag &&
      pricingRuleFlag &&
      (product?.productId === item?.productId || iscoupon || removeDiscounts) &&
      (item?.priority ? item?.priority < pricingRule.priority : true)
    ) {
      const index = cartObj.items.findIndex((p) => p.productId === item.productId && p.upc === item.upc && p.mBatchId === item.mBatchId && p.lineId === product.lineId);
      pricingRule.minimumQty = pricingRule.minimumQty ? pricingRule.minimumQty : 0;

      if (index >= 0) {
        if (Math.abs(cartObj.items[index].weight) >= pricingRule.minimumQty) {
          const discountAmt = parseFloat(discountValue);
          const sp = (parseFloat(cartObj.items[index].realPrice * cartObj.items[index].weight) - discountAmt) / cartObj.items[index].weight;
          const mrp = parseFloat(sp) * cartObj.items[index].weight;
          const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + cartObj.items[index].taxRate / 100) : (mrp * cartObj.items[index].taxRate) / 100;

          // Check if any changes are made
          if (
            sp !== cartObj.items[index].salePrice ||
            tax !== cartObj.items[index].taxAmount ||
            mrp !== cartObj.items[index].nettotal ||
            discountAmt !== cartObj.items[index].discount
          ) {
            hasChanges = true;
          }

          let allDiscounts = cartObj.items[index]?.allDiscounts || [];
          let approvalData = cartObj.items[index]?.approval || [];

          let matchedRuleIndex = allDiscounts.findIndex((d) => d.mPricingruleId === pricingRule.mPricingrulesId);
          if (matchedRuleIndex === -1) {
            allDiscounts.push({
              mPricingruleId: pricingRule.mPricingrulesId,
              discountValue: discountValue,
              discountType: pricingRule.type,
            });
          }

          let matchedApprovalIndex = approvalData.findIndex((d) => d.mPricingrulesId === pricingRule.mPricingrulesId);
          if (matchedApprovalIndex === -1) {
            approvalData.push({
              ...matchingApproval,
              time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              mPricingrulesId: pricingRule.mPricingrulesId,
            });
          }

          cartObj.items[index].salePrice = sp;
          cartObj.items[index].taxAmount = parseFloat(tax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
          cartObj.items[index].nettotal =
            taxIncludeFlag === "Y"
              ? parseFloat(mrp.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))
              : parseFloat((mrp + tax).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
          cartObj.items[index].discount = parseFloat(discountAmt.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
          cartObj.items[index].discountName = pricingRule.printedName;
          cartObj.items[index].type = pricingRule.type;
          cartObj.items[index].mPricingruleId = pricingRule.mPricingrulesId;
          cartObj.items[index].nextRule = pricingRule.nextRule;
          cartObj.items[index].iscoupon = iscoupon;
          cartObj.items[index].couponInput = couponInput;
          cartObj.items[index].referenceId = uniqReferenceId;
          cartObj.items[index].priority = pricingRule.priority;
          cartObj.items[index].mPricingCouponId = mPricingCouponId;
          cartObj.items[index].allDiscounts = allDiscounts;
          cartObj.items[index].approval = approvalData;
        } else {
          const mrp = parseFloat(cartObj.items[index].realPrice) * cartObj.items[index].weight;
          const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + cartObj.items[index].taxRate / 100) : (mrp * cartObj.items[index].taxRate) / 100;

          // Check if any changes are made
          if (tax !== cartObj.items[index].taxAmount || mrp !== cartObj.items[index].nettotal || cartObj.items[index].discount !== 0 || cartObj.items[index].discountName !== "") {
            hasChanges = true;
          }

          cartObj.items[index].taxAmount = parseFloat(tax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
          cartObj.items[index].nettotal =
            taxIncludeFlag === "Y"
              ? parseFloat(mrp.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))
              : parseFloat((mrp + tax).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
          cartObj.items[index].discount = 0;
          cartObj.items[index].discountName = "";
          cartObj.items[index].mPricingruleId = null;
          cartObj.items[index].allDiscounts = [];
          cartObj.items[index].approval = null;
          delete cartObj.items[index].nextRule;
        }
      }
    }

    return item;
  });

  // Check if any changes are made and return the updated cart object
  if (hasChanges) {
    let couponsData = cart.couponInput?.length > 0 ? [...cart.couponInput] : [];
    if (!couponsData.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null) {
      couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: mPricingRulesId });
    }

    let uniqueArray = cart?.manualDiscountData?.lineLevelDiscount || [];
    let lineLevelDiscounts = cart?.manualDiscountData?.lineLevelDiscount || [];
    let totalLevelDiscounts = cart?.manualDiscountData?.totalLevelDiscount || [];

    // Get the highest sequenceNo across both arrays
    let allSequenceNos = [...lineLevelDiscounts.map((item) => item.sequenceNo), ...totalLevelDiscounts.map((item) => item.sequenceNo)];

    // Find the next available sequenceNo
    let nextSequenceNo = 1;
    while (allSequenceNos.includes(nextSequenceNo)) {
      nextSequenceNo++;
    }

    let duplicateIndex = uniqueArray.findIndex((item) => item.lineId === product.lineId);
    const itemData = {
      lineId: product.lineId,
      productId: product.productId,
      discountName: pricingRule.printedName,
      discountValue: discountValue,
      pricingRule: pricingRule.mPricingrulesId,
      sequenceNo: duplicateIndex === -1 ? nextSequenceNo : lineLevelDiscounts[duplicateIndex].sequenceNo,
    };
    if (duplicateIndex === -1) {
      uniqueArray.push(itemData);
    } else {
      uniqueArray[duplicateIndex] = itemData;
    }

    let manualDiscountData = {
      lineLevelDiscount: uniqueArray,
      ...cart.manualDiscountData,
    };

    cartObj.iscoupon = iscoupon;
    cartObj.couponInput = couponsData;
    cartObj.manualDiscountData = manualDiscountData;
    setCart(cartObj);
    localStorage.setItem("cartObj", JSON.stringify(cartObj));
    return cartObj;
  } else {
    return cartObj;
  }
};
