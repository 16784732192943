import { Modal } from "antd";

export function useSingleInstance(basePath) {
  const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
  sessionStorage.setItem("tabId", tabId);

  const instanceId = window.name || `${basePath}-${Date.now()}`;
  window.name = instanceId;

  const setActiveTab = () => {
    localStorage.setItem("activeTabId", JSON.stringify({ tabId, instanceId, basePath }));
  };

  const clearActiveTab = () => {
    const activeTabData = JSON.parse(localStorage.getItem("activeTabId") || "{}");
    if (activeTabData.tabId === tabId && activeTabData.instanceId === instanceId && activeTabData.basePath === basePath) {
      localStorage.removeItem("activeTabId");
    }
  };

  const isAnotherTabOpen = () => {
    const activeTabData = JSON.parse(localStorage.getItem("activeTabId") || "{}");
    return activeTabData.tabId && activeTabData.instanceId && activeTabData.basePath === basePath && (activeTabData.tabId !== tabId || activeTabData.instanceId !== instanceId);
  };

  const showWarningModal = () => {
    Modal.warning({
      title: (
        <p
          style={{
            fontWeight: 800,
          }}
        >
          POS Application Already Open
        </p>
      ),
      content: (
        <div>
          <p>The POS application is currently active in another tab or browser.</p>
          <p>Please close any other instances of the POS application and click 'OK' to proceed.</p>
        </div>
      ),
      width: 600,
      onOk: () => {
        clearActiveTab();
        window.location.reload();
      },
    });
  };

  const initializeTab = () => {
    if (!localStorage.getItem("activeTabId")) {
      setActiveTab();
      return true;
    } else if (isAnotherTabOpen()) {
      showWarningModal();
      return false;
    } else {
      setActiveTab();
      return true;
    }
  };

  // Listen for tab changes
  window.addEventListener("storage", (event) => {
    if (event.key === "activeTabId" && event.newValue) {
      const activeTabData = JSON.parse(event.newValue || "{}");
      if (activeTabData.basePath === basePath && (activeTabData.tabId !== tabId || activeTabData.instanceId !== instanceId)) {
        showWarningModal();
      }
    }
  });

  // Clear active tab when the tab is closed or refreshed
  window.addEventListener("unload", clearActiveTab);

  return { initializeTab };
}
